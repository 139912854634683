import axios from 'axios';

new Vue({
  el: '#mostread',
  data: function() {
    return {
      excluded: '',
      posts: [],
      error: false,
    }
  },
  methods: {
    async getPosts() {
      try {
        const response = await axios.get(`/wp-json/wordpress-popular-posts/v1/popular-posts?_embed&limit=10&range=custom&time_quantity=1&time_unit=hour&cat=${this.excluded}`);
        if (response.status === 200 && Array.isArray(response.data) && response.data.length > 0){
          const posts = response.data;
          sessionStorage.setItem('mostread', JSON.stringify(posts));
          this.posts = posts;
        }
      } catch(e) {
        this.error = e;
      }
    }
  },
  async created() {
    const mostread = document.getElementById('mostread');
    if (typeof(mostread) != 'undefined' && mostread != null) {
      if ('excluded' in mostread.dataset){
        this.excluded = mostread.dataset.excluded;
      }
    }
    const posts = sessionStorage.getItem('mostread');
    if (posts) {
      this.posts = JSON.parse(posts);
    }else {
      this.getPosts();
    }
  }
});